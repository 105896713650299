.profile {
    display : flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    .profileForm {
        width : 80%;
        max-width : 700px;

        border: 1px solid lightgray;
        border-radius: 0 0 1em 1em;
        box-shadow: 2px 1px 3px #888888;
        

        background: whitesmoke;
        padding : 1em 1em 1em 1em;

        &::before {
            content: '';            
            background-image: url('../../../assets/images/signup.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center bottom;
            height : 140px;

            margin : -1em -1em 1em -1em;
            display: block;
            margin-bottom : 1em;
        }
    }
}