

/* style here are for the plumbing of the app, so just the menu's appbar and bottom nav */

body {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;    
    overflow-x : hidden;
}

#root {
    display: flex;
    height: 100vh;   
    
    background: #f3f3c9; //0b5248 //f7f7dd //bdbdea

    &:after {
        content: '';
        position: absolute;
        z-index : 1;
        width : 100%;
        height : 100%;
        display: block;
        background: url('../images/bg.png'); 
    }
}

main {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    .container {
        flex: 1 1;
        position : relative;        
                
        & > * {
            z-index : 50;
            position : absolute;
            width : 100%;
            height : 100%;
            top : 0;

            box-sizing: border-box;
            overflow-y: auto;
        }

        .screen-enter {
            left : 100%;
        }

        .screen-enter.screen-enter-active {
            left : 0;
            transition: left 250ms ease-in;
        }

        .screen-exit {
            left : 0;            
        }

        .screen-exit.screen-exit-active {
            left : -100%;
            transition: left 250ms ease-in;
        }
    }
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.centeredFlex {
    display : flex;
    justify-content : center;
}