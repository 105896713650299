#callsWaiting {

    display : flex;
    justify-content: center;
    

    h1,h2,h3,h4 {
        text-align: center;
        color : white;
    }

    h2 {
        margin-top : 0;
    }

    img {
        width : 7em;
        height : 7em;
    }

    text-align: center;

    display : none;
    position : absolute;

    top : 0;
    left : 0;
    width : 100%;
    height : 100%;

    z-index: 9999;

    pointer-events: none;

    &.active {
        box-sizing: border-box;
        padding : 1ex; 

        display : flex;
        flex-direction: column;

        pointer-events: initial;
        background : #0b5248;
    }    

    ul {
        margin : 0;
        padding : 0;
        list-style: none;
    }

    .actionButtons {
        display : flex;
        justify-content : space-around;
        align-items : center;   
        
        .MuiButtonBase-root {
            margin : 0 1ex!important;
        } 
    }
}