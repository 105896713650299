@import "../../../assets/sass/variables.scss";

.drawerMenu {
    color : white;
    a {
        color : white;
    }

    path {
        fill : white;
    }

    .toolbar {
        justify-content: space-around;

        
    }

    & > .MuiPaper-root {
        width : $drawerWidth;
        border-right : 1px solid rgba(0, 0, 0, .33);
        background: #f7f7dd;
        
        &:after {
            content: '';
            position: absolute;
            z-index : 1;
            width : 100%;
            height : 100%;
            display: block;
            background: url('../../../assets/images/bg.png');            
        }
    }

    .content {
        position: relative;
        z-index : 4;
    }

    .background {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 2;
        position: absolute;
        
        opacity: 0.9;

        /*
        //owl logo
        background-image: url('../../../assets/images/logo.svg');
        background-size: 5em;
        background-position: 10px calc(100% - 10px);
        background-repeat: no-repeat;
        */
        
        &:after {
            content: '';
            position: absolute;
            z-index : 3;
            width : 100%;
            height : 100%;
            display: block;
            background: black;            
            opacity: 0.2;
        }
    }  
    
    .readerBar {
        display : flex;
        justify-content: space-between;
        align-items : center;

        & > .slider {
            flex : 1 1 auto;
        }

        button {
            padding : 6px;
        }
    }
}

phone {
    position: relative;
    z-index : 10;
}

@media only screen and (min-width: map-get($breakpoints, tablet)) {
    desktop, phone {
        display : none;
    }

    @media only screen and (min-width: map-get($breakpoints, huge)) {
        tablet.hide-on-desktop {
            display : none;
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, tablet) - 1px) {
    /* nav is on the bottom */
    #root {
        flex-direction : column;
    }

    nav {
        order: 2;
    }

    header {
        position: static!important;
    }

    desktop, tablet {
        display : none;
    }
}

@media only screen and (min-width: map-get($breakpoints, huge)) {
    nav {
        width : $drawerWidth;
        flex-shrink: 0;
    }

    header {
        margin-left: $drawerWidth!important;
        width: calc(100% - #{$drawerWidth})!important;
    }
    
    phone {
        display : none;
    }

    tablet, desktop {
        display: contents;
    }
}