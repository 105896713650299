@import "../../../assets/sass/variables.scss";

.spreadscreen {
    justify-content: flex-start;
    display : flex;
    flex-direction: column;

    .spread {
        flex : 1;      
        padding: 0;

        position : relative;
        display : flex;
        overflow: hidden;

        .cardsPart {
            padding : 1ex;
            flex : 1;
            
        }

        .consultants {
            &.open {
                width : 175px;
            }
            &.closed {
                width : 0;
            }

            background-image: linear-gradient(90deg, rgba(114, 75, 156,0) 0%, rgba(114, 75, 156,0.5) 40%, rgba(114, 75, 156,1) 100%); 

            overflow-x: hidden;
            overflow-y: auto;

            svg {
                cursor: pointer;
            }
        }

        .consultantselector {
            position : absolute;
            background: rgba(255,255,255,0.8);
            right : 0;

            transition: height 1s;
            transition-timing-function: ease;
            
            &.open {
                height : 100%;
            }
            &.closed {
                height : 0;
            }

            bottom : 0;
            
            width : 500px;
            max-width: 50%;

            overflow-x: hidden;
            overflow-y: auto;
            z-index : 10000;
        }
    }

    .actions {
        display : flex;
        padding : 1em;

        justify-content: center;
    }

    .readerselectoritem {
        position : relative;
        display : block;
        max-width : 1050px;
        margin : auto;
    
        h2 {
            margin-bottom : 0;
        }
    
        .readerInformation {
            cursor: pointer;

            position: absolute;
            z-index : 10;    
            
            top : 15px;
            left : 15px;
            
            padding-right : 140px;
            width : 460px;
    
            color : inherit;
            text-decoration: none;
    
            ul {
                margin : 0;
                padding-left : 2em;
            }

            
        }  
        
        @media only screen and (max-width: map-get($breakpoints, tablet) - 1px) {
            font-size: 10px;
            line-height: 1.2em;

            .readerInformation {
                top : 15px;
                left : 12px;
            }
        }
    }
}

.consultantMenuItem {
    padding : 3px!important;
    margin : 0;

    ul {
        margin : 0;
        padding : 0;

        li {
            &::before {
                content : '·';
                margin : 0 3px;
            }
            display: inline-block;              
        }
    }

    figure {
        margin : 0 0.5ex 0.5ex 0;
        width : 3em;
        height : 3em;
        
        img {
            width : 100%;
            height : 100%;

            object-fit: cover;
        }

        
    }
}