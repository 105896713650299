.qrcodes {
    justify-content: center;

    img.banner {
        max-height : 150px;
    }

    & > div {
        text-align : center;
    }

    & > .links {
        display: flex;
        justify-content : space-evenly;
        flex-direction: row;

        figure {
            text-align : center;
            * {
                display: block;
                margin : auto;
            }
        }

        img {
            max-width : 200px;
        }
    }
}