.conversationsscreen, .conversationdetails {
    justify-content: flex-start;
    padding: 1em;
    
    display : flex;
    flex-direction: column;

    .audienceListItem {
        & > .MuiTouchRipple-root {
            display : none!important;
        }
    }

    .conversationOverview {
        list-style : none;
        padding : 0;
        margin : 0;
    }

    .audienceListItem {
        flex-direction: column;
    }

    .audienceTitle {
        width : 100%;

        display : flex;
        align-items: center;

        & > figure, & > svg {
            width : 2em;
            height : 2em;
        }

        .lastMessage {
            flex : 1 1 auto;
            padding-right : 1ex;
            font-size : 110%;

            em {
                float : right;
            }

            & > span {
                display : block;
         
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;                
            }
        }
        
        .controls {
            .icnBtn {
                padding : 3px;

                svg {
                    width : 1.6em;
                    height : 1.6em;
                }
            }

            & > span > svg {
                width : 1.5em;
                height : 1.5em;
            }
        }
    }

    .prevConversations {
        width : 100%;

        .audienceListItem {
            border : 1px solid #ccc;
            border-width : 1px 0 0 0;

            flex-direction: row;
            span {
                flex : 1 1 auto;
            }

            em {
                margin-top : 1ex;
                float : right
            }
        }
    }

    .messages-list {
        line-height: 1.1rem;
        flex: 1;
        display : flex;
        flex-direction: column;
        font-size : 115%;
        overflow-y: auto;

        ul {
            margin : 0;
            padding : 0;
        }
       
        li {
            display : block;
            margin-top: 1ex;

            &.audio {
                & > div {
                    width : 75%;
                }
            }

            &::after{
                clear:both;
            }

            &.side-to {
                padding-left : 20px;
                text-align : left;

                button {
                    display: block;
                    margin : 1ex 0 0 auto;
                }
            }

            &.side-from {
                padding-right : 20px;
                text-align : right;

                button {
                    display: block;
                    margin : 1ex auto 0 0;
                }
            }

            & > div {
                text-align : left;
                display: inline-block;
                max-width : 75%;
                padding : 1ex;
                border-radius: 6px;
                position : relative;

                figure {
                    display: block;
                    border-radius : 1ex 1ex 0 0;
                    z-index : 1;
                    margin : 0;
                    padding : 1ex;

                    &.imgPreview {
                        cursor: pointer;

                        img {
                            width : 150px;
                            height : 150px;
        
                            margin : 0 1ex 1ex 0;
                            object-fit: cover;
                        }                        
                    }

                    svg {
                        height : 10em;
                    }
                }

                img.action {
                    float : left;
                    margin-right : 1ex;
                    
                    height : 50px;
                    width : 50px;
                }

                & .tri-to, & .tri-from {
                    position : absolute;
                    width : 16px;
                    height : 16px;
                    bottom : 0;
                }

                & .tri-to {
                    left : -12px;

                    border-top: 16px solid transparent;
                    border-bottom: none;
                    border-right: 16px solid green;
                }

                & .tri-from {
                    position : absolute;
                    right: -12px;

                    border-top: 16px solid transparent;
                    border-bottom: none;
                    border-left: 16px solid green;
                }
            }
        }
    }

    .draft {
        .input {
            z-index : 10;
            display: flex;
            align-items: center;

            .inputbox {
                margin-left: 1em;
                flex:1;
            }

            hr {
                width : 1px;
                height : 28px;
                margin : 4px;
            }
        }

        figure {
            display: inline-block;
            position : relative;
            border-radius : 1ex 1ex 0 0;
            z-index : 1;
            margin : 0 0 0 1ex;
            padding : 1ex 2em 1ex 1ex;
            text-align: center;
            
            &.imgPreview {
                img {
                    width : 150px;
                    height : 150px;

                    margin : 0 1ex 1ex 0;
                    object-fit: cover;
                }
            }

            svg {
                height : 10em;
            }            

            button {
                position : absolute;
                top : 0;
                right : 0;
            }
        }                
    }

    .spacer {
        padding-top : 1ex;
        display : block;
    }
}

.conversationdetails {
    padding : 0;
}