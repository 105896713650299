.cardtable {
    .cardControls {
        position : absolute;
        display : flex;
        justify-content: center;
    }

.card {
    perspective: 1000px;
    z-index: 5;
    pointer-events: none;
    
    &.animate {
        transition : 0.6s ease-in-out;
        pointer-events: inherit;
        cursor: pointer;
    }

    &.flipped {
        z-index: reset;
    }
}

.flipped .flipper {
    transform: rotateY(180deg);
}

.flipper {
	transition: 0.6s ease-in-out;
	transform-style: preserve-3d;
    position: relative;
}

.back {
    backface-visibility: hidden;
    position: absolute;
    width : 100%;
    height : 100%;
    transform: rotateY(180deg);
    box-shadow: 0 0 1px #888888;
    
    img {
        position: absolute;
        top : 0;
        left : 0;
        width : 100%;
        height: 100%;
    }
}

.front {
    backface-visibility: hidden;
    position: absolute;
    width : 100%;
    height : 100%;
    z-index: 2;
    transform: rotateY(0deg);


    figure {
        padding : 0;
        margin : 0;
        position : relative;

        width : 100%;
        height : 100%;

        .readerAid {
            position : absolute;
            top : 0;
            right : 0;            
        }


        & > img {
            display : block;
            transition: 0.6s ease-in-out;
            margin: auto;
            
            max-width: 100%;
            max-height: 100%;

            box-sizing: border-box;
            box-shadow: 1px 1px 2px #888888;

            background: whitesmoke;
            border-color : whitesmoke;
            border-style: solid;
            border-width : 3px 3px 23px 3px;
        }

        & > h2 {
            font-family: 'CardFont', "Roboto", "Helvetica", "Arial", sans-serif;;              
            text-align : center;
            padding : 1px;
            position : relative;
            
            margin : -1px;
            line-height: 1.2em;
    
            box-sizing: border-box;
            top: -1.4em;
            right : 0;
            left : 0;

            white-space: nowrap;
            overflow : hidden;
        }

        & > h3 {
            font-family: 'CardFont', "Roboto", "Helvetica", "Arial", sans-serif;;              
            text-align : center;
            padding : 1px;
            position : absolute;
            
            margin : -1px;
            line-height: 1.2em;
    
            box-sizing: border-box;
            top: 0.2em;
            right : 0;
            left : 0;

            white-space: nowrap;
            overflow : hidden;
        }
    }
}
}