.spreadSelection {
    justify-content: flex-start;
    padding: 1em;
    
    .container {
        margin : auto;
        padding : 1ex;
        max-width: 1000px;
    }

    ul {
        list-style: none;
        padding : 0;
        margin : 0;
        
        li {
            cursor: pointer;
            position : relative;
            margin-bottom : 1ex;          
        }

        h2 {
            margin : 0;
        }

        p {
            margin-top : 0;
            margin-bottom: 1em;
        }

        figure {
            background : #067769;
            float : left;
            margin : 0 1ex 1ex 0;
            width : 100px;
            padding : 5px;
            border-radius: 5px;

            svg {
                display: block;
                margin : auto;
                max-height : 100px;
            }
        }
    }
}