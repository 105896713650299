.signup {
    display : flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;


    .digits {
        display : flex;
        align-items : center;

        & > * {
            flex : 1;
            margin : 0 3px;

            input {
                width : 1ex;
                font-size: 1.4rem;
                padding : 14px 0;      
                margin : auto;
            }
        }

        & > span {
            flex : 0 0 auto;
        }
    }

    .signupForm {
        width : 80%;
        max-width : 700px;

        border: 1px solid lightgray;
        border-radius: 0 0 1em 1em;
        box-shadow: 2px 1px 3px #888888;
        

        background: whitesmoke;
        padding : 1em 1em 1em 1em;

        &::before {
            content: '';            
            background-image: url('../../../assets/images/signup.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center bottom;
            height : 140px;

            margin : -1em -1em 1em -1em;
            display: block;
            margin-bottom : 1em;
        }

        .phoneInputs {
            & > * {
                margin-left : 0.5ex;
                margin-right : 0.5ex;
            }
        }
    }
}