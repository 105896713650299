.readersscreen {
    justify-content: flex-start;
    padding: 1em;

    h2 {
        .price {
            font-size : 90%;
            float : right;
            color : #724B9C;
        }
    }
}