.mainMenu {
    display : flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    
    & > img {
        max-height : 100px;
        margin-bottom : 1ex;
        border-radius: 1ex;
    }

    a {
        margin : 1ex;
        
        
        button {
            margin : 0;
            width : 100%;
        }
    }

    .br {
        flex-basis: 100%;
    }

    .mainMenuButton {
        span {
            flex-direction: column;
        }

        img {
            height : 100px;            
        }

        svg {
            height : 100px;
            width : 100px;
        }
    }
}