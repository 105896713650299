@import "../../../assets/sass/variables.scss";

.readerscreen {
    justify-content: flex-start;
    padding: 1em;

    .readerbanner {
        z-index: 20;
    }

    .contactreader {
        position : fixed;
        z-index : 100;
        
        right : 2em;                        
        top : 1em;
                
        .buttons {
            strong {
                display : block;
                text-align: center;
                margin : 1ex 0;

                &.price {
                    margin : 0;
                    color : #724B9C;
                    font-size : 2rem;
                }
            }

            a {
                padding : 0.5ex 1em;
            }

            width : 15em;
            display : inline-block;
            background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));

            border : 1px solid #fff;
            border-radius: 1em 0 1em 0;
            
            img {      
                margin : 0 1em;
                height : 3em;
            }
        }
    }

    .content {
        padding-right : 15.5em;

        .reviews {
            max-width : 14em;
            float : left;
            margin : 0 1em;
            
            & > h4 {
                margin : 0 0 1ex 0;
                text-align: center;
                text-transform: uppercase;      
            }
    
            ul {
                list-style: none;
                padding : 0;
                margin : 0;
                
                li {
                    border : 1px solid #333;
                    border-width: 2px 0;
            
                    margin : 0 0 1em 0;
                    padding : 1ex;
            
                    background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1));
            
                    .rating {
                        display : block;
                        text-align : right;
                    }
            
                    .date {
                        display : block;
                        text-align : right;
                        font-size : 75%;
                    }
            
                    h4 {
                        margin : 0 0 3px 0;                    
                    }
            
                    p {
                        margin : 0;
                    }
                }     
            }   
        }

        .properties {
            display : flex;
            
            justify-content : space-between;
            flex-wrap : wrap;
            

            dl {
                flex: 1 1 auto;
                border : 1px solid aliceblue;
                margin-right : 1ex;
                border-width : 0 0 0 5px;
                border-radius: 0 1em 0 0;
                padding : 1ex 1em;
                background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.0));                
            }

            dt {
                font-weight : bold;
                font-size : 110%;
            }

            ul {
                margin : 0;
                padding : 0;
                list-style : none;
            }
        }       
    }


    
}



@media only screen and (max-width: map-get($breakpoints, tablet) - 1px) {
    .readerscreen {
        .readerbanner .contactreader {
            display : none;
        }

        .content {
            padding-right : 1em;
        }
    }  
}

@media only screen and (max-width: 950px) {
    .readerscreen {
        .reviews {
            float : none!important;
            max-width: none!important;

            ul li:nth-of-type(1n+5){
                display : none;
            }
        }
    }
}

@media only screen and (min-width: map-get($breakpoints, huge)) {
    .readerscreen {
        .reviews {
            max-width : 25%;         
        }
    }
}

