@import "../../../assets/sass/variables.scss";

.readerbanner {
    margin : -1em -1em 0 -1em;
    position : relative;
}

.readerbanner {
    .readericon {
        max-width : calc(250 / 480 * 1600px);
        max-height : 250px;
        pointer-events: none;
    }

    .bgImage {
        z-index : 5;
        position: absolute;
        
        top : 0;
        left : 0;
        height : 21.5vw;
        max-height : 180px;
        width : 100%;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        border-bottom : 2px solid black;
    }   
    
    h2 {
        margin : 0;
        z-index : 50;
        position: absolute;
        right : 1em;
        top : 0;
        left : 0;
        width : 100%;
        height : auto;
        font-weight: 300;
        font-size : 3rem;
        font-size : 5vw;

        color : black;
        text-shadow: 1px 1px 0px white, 0px 0px 3px white;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::before {
            /* used as a dynamic left margin */
            content: "";            
            display: inline-block;
            width: 30%;
            max-width: 260px;     
            height : 2em;
        }
    }

    h3 {
        margin : 0;
        z-index : 40;
        position: absolute;
        left : 0;
        
        height : 30vw;
        max-height : 240px;
        top : 0;
        width : 100%;

        display: flex;
        align-items : flex-end;
        justify-content : flex-start;
        
        font-size : 1rem;
        text-shadow: 0px 0px 4px white;
        
        text-align : right;
        quotes: "„" "“";

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::before {
            content: "";            
            display: inline-block;
            width: 28%;
            max-width: 220px;     
            height : 2em;
        }
    }
}

.readericon {
    position : relative;
    z-index : 5;
    
    .whiteangel {
        fill : white;            
    }
    .color {
        fill : #fabb20;            
    }

    path {
        fill : black;
    }

    .readerInfo {
        path {
            fill : transparent;
        }
    }

    .inner {
        stroke : #1dcc29;

        &.NA {
            stroke : lightgray;
        }
    }
    
    .nameBar {
        fill : rgba(255,255,255,0.7);            
    }

    text {
        text-anchor : middle;
        dominant-baseline: middle;
        font-size : 45px;
    }

    .iconCircle {
            stroke-width : 2px;
            stroke: black;
            fill : #1dcc29;

            &.NA {
                fill : lightgray;
            }
    }

    .availabilityBanner {
        path {
            stroke-width : 1.5px;
            stroke: black;
            fill : #1dcc29;            
        }

        &.NA path {
            fill : lightgray;
        }

        text {
            font-size : 40px;
            text-shadow: 1px 1px 2px white;
        }
    }

    .generalInformation {
        path {
            fill : transparent;
        }

        path:nth-child(1) {
            stroke-width : 8px;
            stroke : black;
            fill : linen;
        }

        path:nth-child(2) {
            stroke-width : 6px;
            stroke : #1dcc29;            
        }

        &.NA path:nth-child(2) {
            stroke : lightgray;
        }
    }
}

.readerlistitem {
    position : relative;
    display : block;
    max-width : 1050px;
    margin : auto;

    h2 {
        margin-bottom : 0;
    }

    .readerInformation {
        position: absolute;
        z-index : 10;    
        
        top : 2.5em;
        left : 2em;
        height : 60%;
        
        padding-right : 250px;
        width : calc(250 / 480 * 1200px - 25px);

        color : inherit;
        text-decoration: none;

        ul {
            padding-left : 2em;
        }
    }    

    .contactreader {
        position: absolute;
        z-index : 4;    
                
        top : 2.5em;
        left : calc(250 / 480 * 1000px);

        padding-left : 140px;
        padding-right : 1ex;
        width : calc(100vw - (250 / 480 * 1000px) - 3em);
        max-width : 500px;

        background: linear-gradient(rgba(188, 163, 198, 0.6), rgba(255, 255, 255, 0));
        border : 1px solid #fff;
        border-radius: 0 1em 0 0;
        border-width : 1px 1px 0 0;

        .buttons {
            display : flex;
            white-space: nowrap;

            a, .a {
                flex-direction: column;
                & > * {
                    justify-content: center;
                }

                svg {
                    width: 1.2em;
                    height: 1.2em;
                }
            }
        }
    }

    .readericon {
        //250 wide and the correct width to go with it
        max-width : calc(250 / 480 * 1400px);
        max-height : 250px;
        pointer-events: none;

        .availabilityBanner {
            text {
                font-size : 30px;            
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, tablet) - 1px) {
    .readerbanner {
        h2 {
            font-size : 5.5vw;
        }        
    }

    .readerlistitem {
        font-size : 2vw;

        h2 {
            font-size: 150%;
            margin-bottom : 0.5vw;
        }

        .readerInformation {
            top : 4.5vw;
            left : 3.5vw;

            padding-right : 30vw;
            width : 80vw;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .readerlistitem {
        .contactreader {   
            & > * {
                display : none;
            }

            .directcontact {
                margin-top : 1ex;
                display: block;
                
                .buttons {
                    flex-direction: column;

                    a, .a {
                        flex-direction: row;
                        padding : 4px!important;

                        & > * {
                            min-width: 0;
                            margin-right : 1ex;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .readerlistitem {
        .contactreader {
            padding-left : 10vw;
            width : 20vw;

            left : 72vw;

            .directcontact {
                strong {
                    display : none;
                }

                .buttons {                    
                    a, .a {  
                        padding : 1.5vw;

                        svg {
                            width : 1em;
                            height : 1em;
                        }

                        justify-content: center;       
                        & > * {
                            margin : 0;
                        }

                        & > *:nth-child(2) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: map-get($breakpoints, huge)) {
    .readerbanner {
        h2 {
            font-size : 3.5rem;
        }
    }   
    
    .readerlistitem {
        .contactreader {
            //remove the image on the left and also the menu to the left
            width : calc(100vw - (250 / 480 * 1000px) - 250px - 3em);
        }
    }
}








